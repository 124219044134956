.inteLigent[data-v-4179deab] {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.topSearchBox[data-v-4179deab] {
  width: 600px;
  margin: auto;
  padding: 24px 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.topSearchBox .el-button[data-v-4179deab] {
  cursor: pointer;
  height: 40px;
  border-radius: 0 4px 4px 0;
}
[data-v-4179deab] .el-input--medium .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px 0 0 4px;
}
.tabsBox[data-v-4179deab] {
  width: 600px;
  margin: auto;
}
[data-v-4179deab] .el-tabs__content {
  display: none;
}
.listContent[data-v-4179deab] {
  width: 80%;
  margin: auto;
}
.listTitle[data-v-4179deab] {
  width: 100%;
  color: #9295A2;
  font-size: 14px;
  margin-bottom: 15px;
}
.listContentBox[data-v-4179deab] {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}
.contentBox[data-v-4179deab] {
  width: 100%;
  padding: 14px 0;
  border-bottom: 2px dashed #DCDFE6;
  color: #444A53;
  cursor: pointer;
}
.contentBox:hover .contentTitle[data-v-4179deab] {
  text-decoration: underline;
}
.contentTitle[data-v-4179deab] {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.content[data-v-4179deab] {
  font-size: 14px;
}